interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const InboxIllustration = (props: SVGProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 395 388"
		fill="none"
		{...props}
	>
		<path
			fill="#F6F1D1"
			d="M384.766 321.943c5.375-12.974-.787-27.849-13.761-33.223-12.975-5.375-27.85.787-33.224 13.761-5.374 12.975.787 27.85 13.762 33.224 12.974 5.374 27.849-.787 33.223-13.762ZM309.264 50.857c14.043 0 25.428-11.385 25.428-25.429C334.692 11.385 323.307 0 309.264 0c-14.044 0-25.429 11.385-25.429 25.428 0 14.044 11.385 25.429 25.429 25.429ZM254.598 186.285c10.556-65.027-33.602-126.3-98.63-136.855-65.028-10.556-126.3 33.603-136.856 98.631-10.555 65.027 33.603 126.3 98.631 136.855 65.028 10.556 126.3-33.603 136.855-98.631Z"
		/>
		<path
			fill="#fff"
			d="M317.534 356.272H192.041c-18.876 0-34.179-15.326-34.179-34.179V99.583c0-18.876 15.326-34.18 34.179-34.18h125.493c18.876 0 34.179 15.326 34.179 34.18v222.51c0 18.876-15.326 34.179-34.179 34.179Z"
		/>
		<path
			fill="#000"
			d="M332.195 357.211H177.38c-11.294 0-20.48-9.186-20.48-20.48V84.899c0-11.294 9.186-20.48 20.48-20.48h154.815c11.294 0 20.48 9.186 20.48 20.48v251.832c0 11.294-9.186 20.48-20.48 20.48ZM177.38 66.343c-10.24 0-18.556 8.339-18.556 18.556v251.832c0 10.24 8.339 18.556 18.556 18.556h154.815c10.24 0 18.556-8.339 18.556-18.556V84.899c0-10.24-8.339-18.556-18.556-18.556H177.38Z"
		/>
		<path
			fill="#D894F8"
			d="M338.907 300.834V99.904c0-12.778-10.359-23.137-23.137-23.137H193.828c-12.778 0-23.137 10.359-23.137 23.137v200.93c0 12.778 10.359 23.137 23.137 23.137H315.77c12.778 0 23.137-10.359 23.137-23.137Z"
		/>
		<path
			fill="#fff"
			d="M388.573 205.809c1.809-18.212-10.767-19.243-10.767-19.243s17.662-13.906 11.133-30.033c-6.529-16.128-17.548-12.921-17.548-12.921s12.623-12.095 5.796-26.757c-6.826-14.661-30.789-10.56-42.541-2.084-11.729 8.453-19.312 18.762-14.363 31.819 4.948 13.058 14.295 12.371 14.295 12.371s-11.363 8.11-6.506 22.977c4.856 14.868 16.677 12.898 16.677 12.898s-23.252 6.185-16.677 27.054c4.994 15.83 16.677 12.875 16.677 12.875s-24.604 10.881-17.731 28.727c6.872 17.846 28.727 9.759 39.242 4.238 10.515-5.521 20.847-24.741 4.146-39.242 0 0 16.563-6.62 18.167-22.702v.023Z"
		/>
		<path
			fill="#000"
			d="M343.466 275.405c-2.589 0-5.131-.366-7.491-1.26-4.559-1.718-7.881-5.177-9.851-10.309-5.429-14.088 7.858-24.031 14.593-28.017-4.192-.526-10.446-3.184-13.585-13.653-1.764-5.842-1.374-11.156 1.146-15.83 3.024-5.589 8.384-8.98 12.118-10.767-4.146-.847-10.125-3.78-13.264-13.332-3.803-11.638 2.039-19.404 5.132-22.519-3.299-.642-9.232-3.162-12.898-12.806-4.467-11.798.344-22.588 14.708-32.942 7.559-5.453 21.396-9.737 32.117-6.942 5.567 1.444 9.668 4.697 11.844 9.416 5.452 11.706-1.054 21.9-4.33 25.978 3.895.023 11.271 1.81 16.105 13.745 5.566 13.745-5.796 25.978-9.965 29.872a13.095 13.095 0 0 1 6.116 4.101c3.047 3.642 4.238 8.957 3.551 15.761-1.375 13.883-13.287 20.87-17.388 22.886 7.56 7.147 8.499 14.73 7.881 19.953-1.1 9.415-7.674 16.906-13.31 19.884-4.444 2.337-14.157 6.804-23.229 6.804v-.023Zm1.26-41.579c.412 0 .779.252.916.642a.96.96 0 0 1-.527 1.191c-.229.115-23.664 10.721-17.227 27.49 1.764 4.582 4.696 7.674 8.728 9.186 9.874 3.712 23.688-2.588 29.163-5.452 5.223-2.749 11.294-9.667 12.302-18.395.572-4.994-.39-12.394-8.339-19.266a1.002 1.002 0 0 1-.321-.894.932.932 0 0 1 .596-.733c.16-.068 16.036-6.62 17.571-21.9.618-6.254-.413-11.088-3.093-14.318-2.886-3.482-6.758-3.871-6.804-3.871-.389-.046-.733-.298-.847-.688a.949.949 0 0 1 .32-1.031c.161-.137 17.021-13.653 10.836-28.91-6.117-15.142-16.288-12.371-16.402-12.348a.988.988 0 0 1-1.1-.435.937.937 0 0 1 .16-1.168c.115-.115 12.027-11.821 5.59-25.681-1.97-4.215-5.521-7.032-10.584-8.361-10.148-2.658-23.298 1.443-30.514 6.643-13.562 9.782-18.166 19.816-14.043 30.698C325.735 158.458 334.348 158 334.44 158a.962.962 0 0 1 .619 1.741c-.436.32-10.722 7.903-6.163 21.9 4.536 13.883 15.166 12.325 15.624 12.256.504-.069.985.229 1.099.733a.942.942 0 0 1-.664 1.123c-.115.022-10.584 3.253-15.051 11.5-2.268 4.192-2.611 9.026-1.008 14.363 4.444 14.776 15.097 12.325 15.532 12.21.069 0 .16-.023.229-.023l.069.023Z"
		/>
		<path
			fill="#000"
			d="M334.532 159.992c-.206 0-.436-.092-.642-.229-.412-.344-.435-.985-.091-1.397.939-1.146 23.71-10.286 37.203-15.624a.93.93 0 0 1 1.237.55.93.93 0 0 1-.55 1.237c-13.905 5.521-35.118 14.089-36.516 15.142a.81.81 0 0 1-.664.321h.023ZM344.749 195.821a.975.975 0 0 1-.939-.733c-.138-.504.183-1.031.687-1.168l33.057-8.293c.504-.137 1.031.183 1.168.687.138.504-.183 1.031-.687 1.169l-33.057 8.293s-.16.022-.229.022v.023ZM344.749 235.751a.977.977 0 0 1-.939-.733.983.983 0 0 1 .71-1.169l25.657-6.277c.504-.137 1.031.184 1.169.711a.983.983 0 0 1-.711 1.168l-25.657 6.277s-.16.023-.229.023Z"
		/>
		<path
			fill="#fff"
			d="M177.38 355.928c-10.79 0-19.518-8.751-19.518-19.518V235.934l.138-49.413s9.598-16.265 23.916-36.837c18.487-26.551-6.941-47.81-27.467-30.743-20.526 17.066-27.949 30.743-31.454 53.583-2.061 13.447 3.299 54.292 3.299 54.292l-19.907 29.025c-25.887 37.662-16.792 89.114 20.411 115.665 36.219 25.841 86.113 18.762 113.809-15.578H177.38Z"
		/>
		<path
			fill="#000"
			d="M175.364 388c-17.067 0-34.271-5.132-49.116-15.715-18.212-12.989-30.376-32.255-34.248-54.27-3.894-22.015.94-44.282 13.608-62.724l19.701-28.727c-.573-4.352-5.246-41.281-3.253-54.201 3.642-23.779 11.683-37.455 31.797-54.178 11.065-9.187 22.885-7.171 29.185-1.375 6.46 5.933 10.24 18.212-.32 33.401-13.242 19.014-22.542 34.545-23.757 36.561l-.137 49.139v100.476c0 10.24 8.339 18.556 18.556 18.556h63.204c.367 0 .71.206.871.55a.944.944 0 0 1-.115 1.008c-16.654 20.64-41.189 31.476-65.999 31.476l.023.023Zm-5.429-274.26c-4.628 0-9.782 1.718-14.845 5.934-19.701 16.379-27.559 29.758-31.133 52.987-2.016 13.172 3.253 53.605 3.299 54.018.023.229-.023.481-.16.664l-19.908 29.025c-25.428 36.997-16.38 88.266 20.183 114.336 35.073 25.016 83.524 18.831 111.197-13.837h-61.165c-11.294 0-20.48-9.186-20.48-20.48V235.911l.137-49.414c0-.16.046-.343.138-.481.091-.16 9.781-16.539 23.962-36.905 9.85-14.157 6.483-25.474.595-30.881-2.978-2.726-7.124-4.513-11.82-4.513v.023Z"
		/>
		<path
			className="fill-[#383D3B] dark:fill-muted-foreground"
			fill="#383D3B"
			d="M179.465 355.928h-2.085a19.487 19.487 0 0 1-17.456-10.789c-.183-.138-.39-.252-.573-.39-34.981-24.947-49.688-67.877-40.296-107.372l-12.668 18.488c-25.887 37.661-16.792 89.113 20.411 115.664 33.744 24.054 79.332 19.564 107.807-8.911-18.326 2.84-37.432.733-55.14-6.667v-.023Z"
		/>
		<path
			fill="#fff"
			d="M263.722 122.72c-4.765-4.65-12.371-4.65-17.136 0l-49.528 47.329v78.668h116.215v-78.668l-49.528-47.329h-.023Z"
		/>
		<path
			fill="#000"
			d="M313.25 249.656H197.036a.97.97 0 0 1-.963-.963v-78.667c0-.252.115-.504.298-.687l49.528-47.329a13.164 13.164 0 0 1 18.464 0l49.528 47.306a.99.99 0 0 1 .298.687v78.668a.968.968 0 0 1-.962.962l.023.023Zm-115.252-1.925h114.29v-77.293l-49.23-47.031c-4.445-4.329-11.386-4.329-15.807 0l-49.253 47.031v77.293Z"
		/>
		<path
			fill="#000"
			d="M197.036 249.655a.98.98 0 0 1-.642-.252.97.97 0 0 1-.069-1.351l37.845-41.785c.344-.39.962-.412 1.352-.069a.97.97 0 0 1 .068 1.352l-37.844 41.785a.977.977 0 0 1-.71.32ZM313.25 249.656a.933.933 0 0 1-.733-.344l-36.539-43.045a.95.95 0 0 1 .114-1.352.953.953 0 0 1 1.352.115l36.539 43.045a.95.95 0 0 1-.115 1.352.945.945 0 0 1-.618.229Z"
		/>
		<path
			fill="#fff"
			d="m263.722 218.317 33.584-32.736v-57.042h-84.074v57.271l33.354 32.507c4.765 4.651 12.371 4.651 17.136 0Z"
		/>
		<path
			fill="#000"
			d="M255.154 222.784c-3.321 0-6.643-1.26-9.232-3.78l-49.528-48.268a.928.928 0 0 1 0-1.351.927.927 0 0 1 1.352 0l49.528 48.268c4.421 4.307 11.362 4.307 15.807 0l49.528-48.268a.966.966 0 0 1 1.351 0 .966.966 0 0 1 0 1.351l-49.528 48.268c-2.589 2.52-5.91 3.78-9.232 3.78h-.046Z"
		/>
		<path
			fill="#000"
			d="M213.232 186.772a.968.968 0 0 1-.962-.962v-57.271c0-.527.435-.962.962-.962h84.074c.527 0 .962.435.962.962v57.042a.968.968 0 0 1-.962.962.968.968 0 0 1-.962-.962v-56.08h-82.15v56.309a.968.968 0 0 1-.962.962Z"
		/>
		<path fill="#0719F5" d="M245.372 141.597h-21.74v21.74h21.74v-21.74Z" />
		<path
			fill="#000"
			d="M269.128 160.977h-15.486a.968.968 0 0 1-.962-.963c0-.526.435-.962.962-.962h15.486a.97.97 0 0 1 .963.962.97.97 0 0 1-.963.963ZM284.614 145.904h-30.972a.968.968 0 0 1-.962-.962c0-.527.435-.963.962-.963h30.972a.97.97 0 0 1 .963.963.968.968 0 0 1-.963.962ZM284.614 176.028h-60.982a.968.968 0 0 1-.962-.962c0-.527.435-.962.962-.962h60.982c.527 0 .962.435.962.962a.968.968 0 0 1-.962.962ZM245.395 191.079h-20.709a.968.968 0 0 1-.962-.963c0-.526.435-.962.962-.962h20.709c.527 0 .962.436.962.962a.968.968 0 0 1-.962.963ZM275.863 191.079h-20.709a.968.968 0 0 1-.962-.963c0-.526.435-.962.962-.962h20.709a.97.97 0 0 1 .963.962.97.97 0 0 1-.963.963ZM205.58 270.48c13.234 0 23.962-10.729 23.962-23.963 0-13.234-10.728-23.962-23.962-23.962s-23.962 10.728-23.962 23.962 10.728 23.963 23.962 23.963Z"
		/>
		<path
			fill="#fff"
			d="M207.848 258.544a.968.968 0 0 1-.962-.962v-19.793l-8.11 8.11a.968.968 0 0 1-1.351 0 .968.968 0 0 1 0-1.352l9.736-9.736a.946.946 0 0 1 1.054-.206c.366.137.595.504.595.893v22.107a.968.968 0 0 1-.962.962v-.023ZM293.732 74.682v8.522c0 7.972-6.483 14.455-14.455 14.455h-48.978c-7.973 0-14.456-6.483-14.456-14.455v-8.522h77.889ZM218.592 295.816a8.192 8.192 0 0 1-8.201 8.201 8.192 8.192 0 0 1-8.201-8.201 8.192 8.192 0 0 1 8.201-8.201 8.192 8.192 0 0 1 8.201 8.201Z"
		/>
		<path
			fill="#0719F5"
			d="M262.37 295.816a8.192 8.192 0 0 1-8.201 8.201 8.192 8.192 0 0 1-8.201-8.201 8.192 8.192 0 0 1 8.201-8.201 8.192 8.192 0 0 1 8.201 8.201Z"
		/>
		<path
			fill="#000"
			d="M306.171 295.816a8.192 8.192 0 0 1-8.201 8.201 8.192 8.192 0 0 1-8.201-8.201 8.192 8.192 0 0 1 8.201-8.201 8.192 8.192 0 0 1 8.201 8.201ZM98.323 222.876c-.252 0-.481-.091-.687-.275l-13.333-13.332a.969.969 0 0 1 0-1.352.967.967 0 0 1 1.352 0l13.332 13.333a.967.967 0 0 1 0 1.351.967.967 0 0 1-.687.275h.023Z"
		/>
		<path
			fill="#000"
			d="M84.99 222.876c-.252 0-.48-.091-.687-.275a.967.967 0 0 1 0-1.351l13.333-13.333a.967.967 0 0 1 1.351 0 .969.969 0 0 1 0 1.352l-13.332 13.332a.967.967 0 0 1-.688.275h.023ZM68.748 149.592c-7.124 0-12.92-5.796-12.92-12.92 0-7.125 5.796-12.921 12.92-12.921 7.125 0 12.92 5.796 12.92 12.921 0 7.124-5.795 12.92-12.92 12.92Zm0-23.916c-6.07 0-10.996 4.925-10.996 10.996 0 6.071 4.925 10.996 10.996 10.996 6.07 0 10.996-4.925 10.996-10.996 0-6.071-4.925-10.996-10.996-10.996Z"
		/>
	</svg>
)
